const state = () => {
  return {
    menu: [

      // Admin Requests
      {
        icon: 'SidebarIcon',
        pageName: 'notifications',
        title: 'Purchase',
        roles: [1,3,8],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'purchase-view',
            title: 'Makeable Purchase',
            roles: [1,3,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'rough-purchase-view',
            title: 'Rough Purchase',
            roles: [1, 3,8],
          }
        ]
      },
      // Transactions
      {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Process',
        roles: [1,2,3,4,8,5,6],
        subMenu: [
          {
            pageName: 'packet-lise-view',
            title: 'Packets',
            roles: [1,2,8,5,6,4]
          },
          {
            pageName: 'single-packet-transaction',
            title: 'Single Packet',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'multiple-issue-transaction',
            title: 'Multiple Issue',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'planner-multiple-issue-transaction',
            title: 'Planning Packets',
            roles: [5,4,6,2,8,1,3]
          },
          {
            icon: 'BoxIcon',
            pageName: 'party-receive-rough-transaction',
            title: 'Multiple Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'planner-multiple-transfer',
            title: 'Plan Done Packets',
            roles: [5,6]
          },
          {
            icon: 'BoxIcon',
            pageName: 'ls-receive-transaction',
            title: 'Recut Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'ls-makeable-transfer',
            title: 'Makeable transfer',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'final-gradding-update',
            title: 'Final Gradding',
            roles: [1]
          },
          {
            icon: 'BoxIcon',
            pageName: 'lab-receive',
            title: 'Lab Receive',
            roles: [1,8]
          },
        ]
      },
       // Transactions
       {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Transactions',
        roles: [1,2,8,4,5,6],
        subMenu: [
          {
            pageName: 'rough-purchase-transaction',
            title: 'Bunch Transactions',
            roles: [1,8,4]
          },
          {
            icon: 'BoxIcon',
            pageName: 'transaction-history',
            title: 'Issue Transactions',
            roles: [1,2,8,4,5,6]
          },
          {
            icon: 'BoxIcon',
            pageName: 'receive-transaction-history',
            title: 'ReceiveTransactions',
            roles: [1,2,8,4,5,6]
          },
        ]
      },
      // Final Polish
      {
        icon: 'AirplayIcon',
        pageName: 'final-polish-stock-available',
        title: 'Final Polish',
        roles: [1,8]
      },
      // Transactions
      {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Discuss',
        roles: [1,2,4,8],
        subMenu: [
          {
            pageName: 'discuss-stocks',
            title: 'Packets',
            roles: [1,2,4,8]
          },
          {
            pageName: 'discuss-transactions',
            title: 'Transaction',
            roles: [1,2,4,8]
          },
        ]
      },
      // Admin Requests
      {
        icon: 'SidebarIcon',
        pageName: 'notifications',
        title: 'Notification',
        roles: [1,2,8],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'notification-update-param',
            title: 'Param Change',
            roles: [1,2,8],
          },
        ]
      },
      // Admin Reports
      {
        icon: 'SidebarIcon',
        pageName: 'reports',
        title: 'Reports',
        roles: [1,2,8,5,6],
        subMenu: [
          {
            icon: 'BoxIcon',
            title: 'Labour Report',
            pageName: 'user_costing_report',
            external: true,
            page_link: 'labour-report',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'user_process_report',
            external: true,
            page_link: 'process-report',
            title: 'Process Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'user_stock_report',
            external: true,
            page_link: 'stock-report',
            title: 'Stock Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'report-ls-labour',
            title: 'LS Report',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'kapan_summary_report',
            title: 'Kapan Summary',
            roles: [1],
          },
          {
            icon: 'BoxIcon',
            pageName: 'packet_stock_summary',
            title: 'Process Stock Report',
            roles: [1],
          },
          {
            icon: 'BoxIcon',
            pageName: 'plan_done_report',
            external: true,
            page_link: 'plan-report',
            title: 'Plan Report',
            roles: [1,2,8,4,5,6],
          },
          {
            icon: 'BoxIcon',
            pageName: 'stock_search_report',
            title: 'Search Stock',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'rtp_report',
            title: 'RTP Report',
            roles: [1,8, 4],
          },
          {
            icon: 'BoxIcon',
            pageName: 'lab_report',
            title: 'LAB Report',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'full_kapan_report',
            title: 'Full Kapan Report',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'report_planning_prediction',
            title: 'Plan Prediction',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'kapan_done_report',
            title: 'Kapan Done Report',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'comparison_report',
            external: true,
            page_link: 'comparison-report',
            title: 'Comparison Report',
            roles: [1,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'assortment-stocks',
            title: 'Assortment Stocks',
            roles: [1,2,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'outward-report',
            external: true,
            page_link: 'job-done-report',
            title: 'Outward Report',
            roles: [1,8],
          }
        ]
      },
      // Outword View
      {
        icon: 'SidebarIcon',
        pageName: 'job-done-list',
        title: 'Job Done',
        roles: [1, 8],
        subMenu: [
          {
            icon: 'MonitorIcon',
            pageName: 'create-jon-done',
            title: 'Job Transfer',
            roles: [1, 8]
          },
          {
            icon: 'MonitorIcon',
            pageName: 'release-stocks',
            title: 'Release Stock',
            roles: [1, 8]
          },
          {
            icon: 'MonitorIcon',
            pageName: 'job-done-list',
            title: 'Transactions',
            roles: [1, 8]
          }
        ]
      },
      {
        icon: 'BoxIcon',
        pageName: 'admin-multiple-packet-plans',
        title: 'Rough Planning',
        roles: [1]
      },
      // Rough To Makeable Transfer
      {
        icon: 'MonitorIcon',
        pageName: 'rough-to-makeable-view',
        title: 'Makeable Transfer',
        roles: [1,2,8]
      },
      {
        icon: 'MonitorIcon',
        pageName: 'makeable-to-rough-view',
        title: 'Rough Transfer',
        roles: [1,2,8]
      },
      // Rough To Makeable Transfer
      {
        icon: 'MonitorIcon',
        pageName: 'urgent-packets-view',
        title: 'Urgent',
        roles: [1]
      },
      // Outword View
      // {
      //   icon: 'MonitorIcon',
      //   pageName: 'outword-packets-view',
      //   title: 'Outward',
      //   roles: [3]
      // },
      // Closings
      {
        icon: 'MonitorIcon',
        pageName: 'manager-closings-view',
        title: 'Closings',
        roles: [2,8]
      },
      {
        icon: 'MonitorIcon',
        pageName: 'admin-closings-view',
        title: 'Closings',
        roles: [1]
      },
      // Master
      {
        icon: 'MonitorIcon',
        pageName: 'master',
        title: 'master',
        roles: [1]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
