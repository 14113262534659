<template>
  <button class="hidden" v-print="'#single-stone'" ref="single_stone_print"></button>
  <div class="hidden">
    <div id="single-stone" class="absolute top-0 left-0 p-0 m-0" v-if="print_data.id">
      <div>
        <table class="p-0 w-full text-black" border="collapse">
          <tr class="text-center m-0 p-0">
              <th colspan="3" class="text-center">
                <p class="p-0 mt-1 text-black w-full text-center"
      style="font-size: 11px;line-height: 1;letter-spacing: 1px;font-weight: bold;"> <span
        class="text-center">{{ print_data.date }}</span> / <span class="text-center">VALA BROTHERS</span></p>
              </th>
          </tr>
            <tr class="text-center">
                <th colspan="3" class="text-center">
                    <VueBarcode :value="print_data.barnum" :options="{ displayValue: false,height:25,width:2 }" tag="svg"></VueBarcode>
                </th>
            </tr>
            <tr class="text-center m-auto">
                <th colspan="1" class="m-auto" style="font-size: 15px;line-height: 12px;">{{ print_data.vepari_no }}</th>
                <th colspan="1" class="m-auto" style="font-size: 15px;line-height: 12px;">{{ print_data.mk_weight ? $h.myFloat(print_data.mk_weight) : $h.myFloat(print_data.weight) }} Ct</th>
                <th colspan="1" class="m-auto" style="font-size: 15px;line-height: 12px;">{{ print_data.stone }}</th>
            </tr>
        </table>
      </div>
    </div>
  </div>
  <button class="hidden" v-print="'#multi-stone'" ref="multi_stone_print"></button>
  <div class="hidden">
    <div id="multi-stone" class="absolute top-0 left-0 p-0 m-0">
      <div style="page-break-before: always;" v-for="(item,index) in print_arr_data" :key="index">
        <div>
          <table class="p-0 w-full text-black" border="collapse">
          <tr class="text-center m-0 p-0">
              <th colspan="3" class="text-center">
                <p class="p-0 mt-1 text-black w-full text-center"
      style="font-size: 11px;line-height: 1;letter-spacing: 1px;font-weight: bold;"> <span
        class="text-center">{{ item.created_at }}</span> / <span class="text-center">VALA BROTHERS</span></p>
              </th>
          </tr>
            <tr class="text-center">
                <th colspan="3" class="text-center">
                    <VueBarcode :value="item.barnum" :options="{ displayValue: false,height:25,width:2 }" tag="svg"></VueBarcode>
                </th>
            </tr>
            <tr class="text-center m-auto">
                <th colspan="1" class="m-auto" style="font-size: 15px;line-height: 12px;">{{ item.vepari_no }}</th>
                <th colspan="1" class="m-auto" style="font-size: 15px;line-height: 12px;">{{ item.mk_weight ? $h.myFloat(item.mk_weight) : $h.myFloat(item.weight) }} Ct</th>
                <th colspan="1" class="m-auto" style="font-size: 15px;line-height: 12px;">{{ item.stone }}</th>
            </tr>
        </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
 import { BASE_URL } from '@/constants/config.js'
import { reactive, ref } from 'vue';
import moment from 'moment';

const page_data = reactive({
  base_url: BASE_URL,
});

const single_stone_print = ref();
const multi_stone_print = ref();

const print_data = reactive({
    id: "",
    vepari_no: "",
    number: "",
    type: "",
    mk_weight: "",
    weight: "",
    polish_weight: "",
    barnum: "",
    shape: "",
    color: "",
    purity: "",
    cut: "",
    polish: "",
    symmetry: "",
    fluerocent: "",
    tension: "",
    stone: "",
    length: "",
    width: "",
    height: "",
    date: "",
  })

const print_arr_data = ref([]);

const setPrintData = (data) => {
  print_data.id = data.id
  print_data.vepari_no = (data.vepari_no ?? "");
  print_data.number = (data.number ?? "");
  print_data.mk_weight = (data.mk_weight ?? "");
  print_data.weight = (data.weight ?? "");
  print_data.polish_weight = (data.polish_weight ?? "");
  print_data.type = (data.type ?? "");
  print_data.stock_type = (data.stock_type ?? "");
  print_data.barnum = (data.barnum ?? "");
  print_data.shape = (data.shape ?? "");
  print_data.color = (data.color ?? "");
  print_data.purity = (data.purity ?? "");
  print_data.cut = (data.cut ?? "");
  print_data.polish = (data.polish ?? "");
  print_data.symmetry = (data.symmetry ?? "");
  print_data.fluerocent = (data.fluerocent ?? "");
  print_data.tension = (data.tension ?? "");
  print_data.stone = (data.stone ?? "");
  print_data.length = (data.length ?? "");
  print_data.width = (data.width ?? "");
  print_data.height = (data.height ?? "");
  print_data.date = (data.date ? moment(data.date).format("DD-MM-YYYY") : "");

  setTimeout(() => {
    single_stone_print.value.click();
  }, 100);
}
const setPrintAllData = (data) => {

  console.log('dataCallaed')

  print_arr_data.value = data

  print_arr_data.value.forEach(element => {
    element.date = (element.date ? moment(element.date).format("DD-MM-YYYY") : "");
  });
  setTimeout(() => {
    multi_stone_print.value.click();
  }, 100);
}

defineExpose({
  setPrintData,
  setPrintAllData
})
</script>
